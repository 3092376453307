import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { LoginSchema } from "../../validations/Validation";
import { useCallback, useEffect, useState } from "react";
import { EyeShowIcon, EyeSlashIcon } from "../../assets";
import AlertComponent from "../../components/AlertComponent";
import { AlertDataProps } from "../../types/Common";
import { loginRequest } from "../../store/reducers/AuthReducer";
import { RootState } from "../../store/reducers/RootReducers";
import { STORE_KEY } from "../../utils/AppConstants";
import Loader from "../../components/Loader";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCaptchaComponent from "./ReCaptchaComponent";
import { aesEncrypt } from "../../utils/Encryption";

const LoginPage = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state: RootState) => state.authState);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });

  useEffect(() => {
    if (authData.result) {
      localStorage.setItem(
        STORE_KEY.SESSION_DATA,
        JSON.stringify(authData.result)
      );
      window.location.replace("/dashboard");
    } else if (authData.error) {
      setAlertData({
        title: "Login Error",
        message: authData.error,
        type: "error",
      });
    }
  }, [authData]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      if (token !== null) {
        const body = {
          email: value.email,
          password: value.password,
          recaptchaToken: token,
        };
        const encryptText = aesEncrypt(JSON.stringify(body));
        const encryptBody = {
          dataCipher: encryptText,
        };
        dispatch(loginRequest(encryptBody));
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (token !== null) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleClipboardEvent = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <Card sx={styles.container}>
        <CardContent sx={styles.cardPadding}>
          <Box sx={styles.inlineSpaceBetween}>
            <Typography sx={styles.titleText}>Login To Continue</Typography>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate>
                <TextField
                  fullWidth
                  autoComplete="email"
                  type="email"
                  label="Email Address"
                  sx={styles.inputStyle}
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  onPaste={handleClipboardEvent}
                  onCopy={handleClipboardEvent}
                  onCut={handleClipboardEvent}
                  sx={styles.inputStyle}
                  {...getFieldProps("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleShowPassword}
                          edge="end"
                          style={{ color: "#FFC800" }}
                        >
                          {showPassword ? <EyeShowIcon /> : <EyeSlashIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
                <GoogleReCaptchaProvider
                  useRecaptchaNet
                  reCaptchaKey={siteKey as string}
                  scriptProps={{ async: true, defer: true, appendTo: "body" }}
                >
                  <Box
                    sx={{ lg: { marginTop: "6em" }, md: { marginTop: "8em" } }}
                  >
                    {authData?.loading ? (
                      <Loader />
                    ) : (
                      <ReCaptchaComponent
                        onSuccess={(token) => {
                          if (token) {
                            setToken(token);
                          }
                        }}
                        onError={(error) => {
                          console.log("Error executed...");

                          if (error) {
                            setAlertData({ message: error, type: "error" });
                          }
                        }}
                      />
                    )}
                  </Box>
                </GoogleReCaptchaProvider>
              </Form>
            </FormikProvider>
          </Box>
        </CardContent>
      </Card>

      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </>
  );
};
export default LoginPage;
